import React from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";



const AnimatedStackedBarChart = ({ width = 960, height = 500, data, customLegendFormatter }) => {
    // Normalize data to percentages
    const normalizeData = (data) =>
        data.map((item) => {
            // Set default values for undefined or non-numeric entries
            const CategoryA = item.CategoryA || 0;
            const CategoryB = item.CategoryB || 0;
            const CategoryC = item.CategoryC || 0;
            const CategoryD = item.CategoryD || 0;
            const CategoryE = item.CategoryE || 0;

            const total = CategoryA + CategoryB + CategoryC + CategoryD + CategoryE;

            if (total === 0) {
                // Handle zero total by assigning 0% to all categories
                return {
                    name: item.name,
                    CategoryA: 0,
                    CategoryB: 0,
                    CategoryC: 0,
                    CategoryD: 0,
                    CategoryE: 0,
                };
            }

            return {
                name: item.name,
                CategoryA: (CategoryA / total) * 100,
                CategoryB: (CategoryB / total) * 100,
                CategoryC: (CategoryC / total) * 100,
                CategoryD: (CategoryD / total) * 100,
                CategoryE: (CategoryE / total) * 100,
            };
        });
    const normalizedData = normalizeData(data);

    console.log(normalizedData);

    return (
        <BarChart
            width={width}
            height={height}
            data={normalizedData}
            margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
            }}
        >
            <CartesianGrid
                stroke="#006fac"
            />
            <XAxis
                dataKey="name"
                style={{ color: '#006fac' }}
                tick={{ fill: '#006fac' }} />
            <YAxis
                tickFormatter={(value) => `${value.toFixed(0)}%`}
                domain={[0, 100]}
                style={{ color: '#006fac' }}
                tick={{ fill: '#006fac' }} />
            <Tooltip
                labelFormatter={(label) => `${label}`}
                formatter={(value, name) => [
                    `${value.toFixed(2)}%`,
                    customLegendFormatter(name), // Use customLegendFormatter for name
                ]}
            />

            <Legend
                formatter={customLegendFormatter}
                wrapperStyle={{
                    paddingTop: 30, // Adds space between the grid and the legend
                }}
            />

            {/* Bars with sequential animation */}
            <Bar
                dataKey="CategoryA"
                stackId="a"
                fill="#006eae"
                isAnimationActive={true}
                animationBegin={0}         // First bar animates immediately
                animationDuration={500}  // Duration for the animation

            />
            <Bar
                dataKey="CategoryB"
                stackId="a"
                fill="#dbabad"
                isAnimationActive={true}
                animationBegin={500}     // Second bar starts after the first finishes
                animationDuration={500}
            />
            <Bar
                dataKey="CategoryC"
                stackId="a"
                fill="#7194c0"
                isAnimationActive={true}
                animationBegin={1000}     // Third bar starts after the second finishes
                animationDuration={500}
            />
            <Bar
                dataKey="CategoryD"
                stackId="a"
                fill="#be1f49"
                isAnimationActive={true}
                animationBegin={1500}     // Third bar starts after the second finishes
                animationDuration={500}
            />
            <Bar
                dataKey="CategoryE"
                stackId="a"
                fill="#e78644"
                isAnimationActive={true}
                animationBegin={2000}     // Third bar starts after the second finishes
                animationDuration={500}
            />
        </BarChart>
    );
};

export default AnimatedStackedBarChart;
