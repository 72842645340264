import './App.css';
import './components/charts/AnimatedStackedBarChart.jsx';
import AnimatedStackedBarChart from './components/charts/AnimatedStackedBarChart.jsx';
import fakeHeader from './images/Screenshot 2024-12-13 at 09.45.14.png';
import AnimatedNumbersTable from './components/tables/AnimatedNumbersTable.jsx';
import { motion } from "framer-motion";
const data = [
  { name: "1970", CategoryA: 60000, CategoryB: 2400, CategoryC: 0, CategoryD: 2400, CategoryE: 2400 },
  { name: "1980", CategoryA: 30000, CategoryB: 1398, CategoryC: 0, CategoryD: 2400, CategoryE: 2400 },
  { name: "1990", CategoryA: 20000, CategoryB: 9800, CategoryC: 0, CategoryD: 2400, CategoryE: 2400 },
  { name: "2000", CategoryA: 5000, CategoryB: 3908, CategoryC: 0, CategoryD: 2400, CategoryE: 2400 },
  { name: "2010", CategoryA: 1890, CategoryB: 5800, CategoryC: 0, CategoryD: 2400, CategoryE: 2400 },
  { name: "2023", CategoryA: 1890, CategoryB: 5800, CategoryC: 2181, CategoryD: 2400, CategoryE: 2400 },
];
const customLegendFormatter = (value) => {
  // Map dataKeys to custom labels
  const labelMap = {
    CategoryA: "Entreprises individuelles",
    CategoryB: "SARL",
    CategoryC: "SA",
    CategoryD: "SARL-S",
    CategoryE: "Autres",
  };
  return labelMap[value] || value; // Default to value if no mapping exists
};

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={fakeHeader} />
      </header>
      <main>
        <motion.h2
          initial={{ x: "-100vw" }} // Start position off-screen to the left
          animate={{ x: 0 }}       // Animate to its final position
          transition={{
            type: "spring",              // Adjust damping for smoothness
            duration: 1.2           // Duration of the animation
          }}>La Sàrl : le statut jurique de référence dans l'artisanat</motion.h2>
        <motion.div className='container'
          initial={{ x: "100vw" }} // Start position off-screen to the left
          animate={{ x: 0 }}       // Animate to its final position
          transition={{
            type: "spring",         // Adjust damping for smoothness
            duration: 1.2           // Duration of the animation
          }}>
          <AnimatedNumbersTable />
        </motion.div>
        <motion.div className='container'
          initial={{ x: "-100vw" }} // Start position off-screen to the left
          animate={{ x: 0 }}       // Animate to its final position
          transition={{
            type: "spring",            // Adjust damping for smoothness
            duration: 1.2           // Duration of the animation
          }}>
          <AnimatedStackedBarChart data={data} customLegendFormatter={customLegendFormatter} />
        </motion.div>
      </main>

    </div >
  );
}

export default App;
