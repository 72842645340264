import React, { useEffect, useState, useRef } from 'react';

const AnimatedNumber = ({ targetNumber }) => {
    const [currentNumber, setCurrentNumber] = useState(0);
    const elementRef = useRef(null);
    const [hasAnimated, setHasAnimated] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && !hasAnimated) {
                    setHasAnimated(true);
                    animateNumber();
                }
            },
            { threshold: 0.1 } // Adjust the threshold as needed
        );

        if (elementRef.current) {
            observer.observe(elementRef.current);
        }

        return () => {
            if (elementRef.current) {
                observer.unobserve(elementRef.current);
            }
        };
    }, [hasAnimated]);

    const animateNumber = () => {
        const start = 0;
        const end = targetNumber;
        const duration = Math.max(400, end * 0.5); // Set duration proportional to the target number, minimum 1 second
        const increment = end / (duration / 16); // Approx. 60 frames per second
        let current = start;

        const step = () => {
            current += increment;
            if (current >= end) {
                setCurrentNumber(end);
            } else {
                setCurrentNumber(Math.round(current));
                requestAnimationFrame(step);
            }
        };

        requestAnimationFrame(step);
    };

    return (
        <div ref={elementRef} style={{ color: 'black' }}>
            {currentNumber}
        </div>
    );
};

export default AnimatedNumber;