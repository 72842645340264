import AnimatedNumber from "../misc/AnimatedNumber";

const AnimatedNumbersTable = () => {


    return (<table>
        <thead>
            <tr>
                <th className='empty'></th>
                <th>1990</th>
                <th>2000</th>
                <th>2010</th>
                <th>2020</th>
                <th className='highlight'>2023</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Total</td>
                <td><AnimatedNumber targetNumber="3724" /></td>
                <td><AnimatedNumber targetNumber="4153" /></td>
                <td><AnimatedNumber targetNumber="5499" /></td>
                <td><AnimatedNumber targetNumber="8499" /></td>
                <td className='highlight'><AnimatedNumber targetNumber="8944" /></td>
            </tr>
            <tr>
                <td>Entrepises individuelles</td>
                <td><AnimatedNumber targetNumber="2476" /></td>
                <td><AnimatedNumber targetNumber="1803" /></td>
                <td><AnimatedNumber targetNumber="1388" /></td>
                <td><AnimatedNumber targetNumber="1899" /></td>
                <td className='highlight'><AnimatedNumber targetNumber="2047" /></td>
            </tr>
            <tr>
                <td><span>Dont:</span> Sàrl</td>
                <td><AnimatedNumber targetNumber="1245" /></td>
                <td><AnimatedNumber targetNumber="2350" /></td>
                <td><AnimatedNumber targetNumber="4111" /></td>
                <td><AnimatedNumber targetNumber="6132" /></td>
                <td className='highlight'><AnimatedNumber targetNumber="6897" /></td>
            </tr>
            <tr>
                <td><span></span>SARL-S</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td><AnimatedNumber targetNumber="455" /></td>
                <td className='highlight'><AnimatedNumber targetNumber="5022" /></td>
            </tr>
            <tr>
                <td><span></span>SA</td>
                <td><AnimatedNumber targetNumber="145" /></td>
                <td><AnimatedNumber targetNumber="505" /></td>
                <td><AnimatedNumber targetNumber="911" /></td>
                <td><AnimatedNumber targetNumber="1033" /></td>
                <td className='highlight'><AnimatedNumber targetNumber="1013" /></td>
            </tr>
            <tr>
                <td><span></span>Autres</td>
                <td><AnimatedNumber targetNumber="157" /></td>
                <td><AnimatedNumber targetNumber="80" /></td>
                <td><AnimatedNumber targetNumber="37" /></td>
                <td><AnimatedNumber targetNumber="30" /></td>
                <td className='highlight'><AnimatedNumber targetNumber="38" /></td>
            </tr>
        </tbody>
    </table >);
}
export default AnimatedNumbersTable;